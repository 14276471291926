const formModel = {
  
  formId: 'calculatorForm',
  
  currencySymbol: '$',
  
  formField: {
    
    // Screen 1
    DeviceCostSMBG: {
      name: 'DeviceCostSMBG',
      label: 'Cost',
      //requiredErrorMsg: 'This field is required', // Example of a field specific error message.
    },
    DeviceUsageSMBG: {
      name: 'DeviceUsageSMBG',
      label: 'Lifetime (years)',
    },
    DeviceCostCGM: {
      name: 'DeviceCostCGM',
      label: 'Cost',
    },
    DeviceUsageCGM: {
      name: 'DeviceUsageCGM',
      label: 'Lifetime (years)',
    },
    /*ReceiverCostCGM: {
      name: 'ReceiverCostCGM',
      label: 'Cost',
    },
    ReceiverUsageCGM: {
      name: 'ReceiverUsageCGM',
      label: 'Lifetime (years)',
    },*/
    CostLancingDevice: {
      name: 'CostLancingDevice',
      label: 'Cost',
    },
    LancetUsageSMBG: {
      name: 'LancetUsageSMBG',
      label: 'Lifetime (years)',
    },
    
    // Screen 2
    SensorCGMUsageWeeks: {
      name: 'SensorCGMUsageWeeks',
      label: 'Weeks / year',
    },
    SensorCGMUsage: {
      name: 'SensorCGMUsage',
      label: 'Days',
    },
    SensorCGMCost: {
      name: 'SensorCGMCost',
      label: 'Cost',
    },
    IncompleteUsageCGMPerc: {
      name: 'IncompleteUsageCGMPerc',
      label: 'Percent',
    },
    TransmitterCostCGM: {
      name: 'TransmitterCostCGM',
      label: 'Cost',
    },
    TransmitterUsageCGM: {
      name: 'TransmitterUsageCGM',
      label: 'Transmitters / year',
    },
    
    // Screen 3
    LancetCostSMBG: {
      name: 'LancetCostSMBG',
      label: 'Cost',
    },
    LancetDurationSMBG: {
      name: 'LancetDurationSMBG',
      label: 'Test / lancet',
    },
    CostTestStripSMBG: {
      name: 'CostTestStripSMBG',
      label: 'Cost',
    },
    TestFrequencySMBG: {
      name: 'TestFrequencySMBG',
      label: 'Test / day',
    },
    TestFrequencyCGM: {
      name: 'TestFrequencyCGM',
      label: 'Test / day',
    },
    
    // Screen 4
    CalculationCycle: {
      name: 'CalculationCycle',
      label: 'Lifetime (years)',
    },
    TotalPatients: {
      name: 'TotalPatients',
      label: 'Patients',
    },
    PresentValueDiscount: {
      name: 'PresentValueDiscount',
      label: 'Percent',
    },
    OtherCostsSMBG: {
      name: 'OtherCostsSMBG',
      label: 'Cost per patient',
    },
    OtherCostsCGM: {
      name: 'OtherCostsCGM',
      label: 'Cost per patient',
    },
    
  },
  
  errorMessage: {
    requiredErrorMsg: 'This field is required',
    numberErrorMsg: 'You must specify a number',
    positiveErrorMsg: 'Please insert a positive number',
    zerototenErrorMsg: 'Please enter a value between 0 and 10',
    onetofourErrorMsg: 'Please enter a value between 1 and 4',
    onetotenErrorMsg: 'Please enter a value between 1 and 10',
    zerototwentyErrorMsg: 'Please enter a value between 0 and 20',
    onetotwentyErrorMsg: 'Please enter a value between 1 and 20',
    zerotofiftyErrorMsg: 'Please enter a value between 0 and 50',
    onetofiftyErrorMsg: 'Please enter a value between 1 and 50',
    zerotofiftytwoErrorMsg: 'Please enter a value between 0 and 52',
    zerotohundredErrorMsg: 'Please enter a value between 0 and 100',
    onetohundredErrorMsg: 'Please enter a value between 1 and 100',
  }
  
};

export default formModel;