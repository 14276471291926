import { useField } from 'formik';
import {create, all} from "mathjs";

const math = create(all, {});


// Global Functions //

export function isEmpty(str=null) {
	return ( str === null || str.length === 0 );
}

export function processRounding(value, round) {
	
	let split;
	let precision = 0;
	
	if ( isNaN(value) || isEmpty(value) ) {
		return null;
	}
	
	// Try to split round into type and precision if : is present
	try {
		if (round.indexOf(':') !== -1) {
			split = round.split(":");
			precision = split[1];
			round = split[0];
		}
	} catch(e) {}
	
	
	// Round if only a number
	try {
		if ( math.hasNumericValue(round) ) {
			precision = round;
			round = 'round';
		}
	} catch(e) {}
	
	
	if ( math.isZero(value) ) {
		value = 0;
	} else if ( round === 'ceil' ) {
		value = math.ceil( value, precision );
	} else if ( round === 'floor' ) {
		value = math.floor( value, precision );
	} else if ( round === 'round' ) {
		value = math.round( value, precision );
	}
	
	
	return value;
}

/*
export function localeCurrencySymbol_old(locale, currency) {
	
	return new Intl.NumberFormat('de-DE', {
		style: 'currency',
		currency: 'EUR'
	}).format(1);
	
	return new Intl.NumberFormat(locale, {
		style: 'currency',
		currency:currency,
	})
		.formatToParts("1")
		.find(part => part.type = "currency").value;
}
*/

// Local Functions //

// Check form for errors
function numCheckFail(num, field='') {
	
	if ( typeof window.formRef === 'undefined' ) return false;
	
	// Recursively loop through object items
	if ( typeof num === 'object' ) {
		
		for (var f in num) {
			if ( numCheckFail(num[f], f) ) return true;
		}
		
		return false;
		
	}
	
	// Fail on empty but not zero
	if ( isEmpty(num) ) {
		return true
	}
	
	// Fail if not a number
	try {
		if ( ! math.isNumeric(num) ) {
			return true;
		}
	} catch(e) {
		return true;
	}
	
	// Fail if form error is found
	if ( ! isEmpty(window.formRef.current.errors[field]) ) {
		return true;
	}
	
	return false; // No issues found
	
} // numCheckFail()

function GetFieldValue(field) {
	
	const fieldObj = useField(field);
	
	if (typeof fieldObj[1].value !== 'undefined') {
		return fieldObj[1].value;
	} else {
		return null;
	}
	
}

// Dynamic Calculations //

// Page 2

/**
 * SensorCGMUsageWeeks * 7 / SensorCGMUsage
 */
export function SensorsCGMNoStandard(data=[]) {
	
	if ( data.length === 0 ) {
		
		data = {
			'SensorCGMUsageWeeks': GetFieldValue('SensorCGMUsageWeeks'),
			'SensorCGMUsage': GetFieldValue('SensorCGMUsage'),
		};
		
		if ( numCheckFail(data) ) return null; // Validation
		
	}
	
	return (data.SensorCGMUsageWeeks * 7) / data.SensorCGMUsage;
}

/**
 * 0.5 * TotalSensors * IncompleteUsageCGMPerc
 * AKA: SensorsCGMNoAdditional from Excel
 */
export function SensorsCGMWasted(data=[]) {
	
	if ( data.length === 0 ) {
		
		data = {
			'SensorsCGMNoStandard': SensorsCGMNoStandard(),
			'IncompleteUsageCGMPerc': GetFieldValue('IncompleteUsageCGMPerc'),
		};
		
		if (numCheckFail(data)) return null;
		
	}
	
	if ( math.isZero(data.IncompleteUsageCGMPerc) ) {
		return 0;
	} else {
		return 0.5 * data.SensorsCGMNoStandard * (data.IncompleteUsageCGMPerc / 100);
	}
	
}

export function SensorsCGMTotalSensors(data=[]) {
	
	if (data.length === 0) {
		
		data = {
			'TotalSensors': SensorsCGMNoStandard(),
			'Waste': SensorsCGMWasted(),
		};
		
		if (numCheckFail(data)) return null; // Validation
		
	}
	
	return data.TotalSensors + data.Waste;
}

export function SensorsCGMTotalCost(data=[]) {
	
	if (data.length === 0) {
		
		data = {
			'SensorCGMUsageWeeks': GetFieldValue('SensorCGMUsageWeeks'),
			'TotalSensors': SensorsCGMTotalSensors(),
			'SensorCGMCost': GetFieldValue('SensorCGMCost'),
			'TransmitterCostCGM': GetFieldValue('TransmitterCostCGM'),
			'TransmitterUsageCGM': GetFieldValue('TransmitterUsageCGM'),
		};
		
		//if ( numCheckFail(data) ) return null; // Validation
		
	}

	const SensorCost = data.TotalSensors * data.SensorCGMCost;
	
	//const TransmitterCost = (data.SensorCGMUsageWeeks * 7) / data.TransmitterUsageCGM * data.TransmitterCostCGM; // Original formula for Max usage days.
	const TransmitterCost = data.TransmitterUsageCGM * data.TransmitterCostCGM; // New formula for transmitters used per year.
	
	return SensorCost + TransmitterCost;
}


// Page 3

export function LancetCost(data=[]) {
	
	if (data.length === 0) {
		
		data = {
			'LancetCostSMBG': GetFieldValue('LancetCostSMBG'),
		};
		
		if (numCheckFail(data)) return null; // Validation
		
	}
	
	return data.LancetCostSMBG;
	
}

export function LancetDuration(data=[]) {
	
	if (data.length === 0) {
		
		const data = {
			'LancetDurationSMBG': GetFieldValue('LancetDurationSMBG'),
		};
		
		if (numCheckFail(data)) return null; // Validation
		
	}
	
	return data.LancetDurationSMBG;
	
}

export function CostTestStrip(data=[]) {
	
	if (data.length === 0) {
		
		data = {
			'CostTestStripSMBG': GetFieldValue('CostTestStripSMBG'),
		};
		
		if (numCheckFail(data)) return null; // Validation
		
	}
	
	return data.CostTestStripSMBG;
	
}

export function TestsPA_SMBG(data=[]) {
	
	if (data.length === 0) {
		
		data = {
			'TestFrequencySMBG': GetFieldValue('TestFrequencySMBG'),
		};
		
		if (numCheckFail(data)) return null; // Validation
		
	}
	
	return data.TestFrequencySMBG * 52 * 7; // Original version used 52 * 7 (364)
	//return data.TestFrequencySMBG * 365; // This was how the excel document calculated thie field when I started the project, but this was incorrect.
	
}

export function TestsPA_CGM(data=[]) {
	
	if (data.length === 0) {
		
		data = {
			'TestFrequencyCGM': GetFieldValue('TestFrequencyCGM'),
			'SensorCGMUsageWeeks': GetFieldValue('SensorCGMUsageWeeks'), // Page 2
			'TestFrequencySMBG': GetFieldValue('TestFrequencySMBG'),
		};
		
		if (numCheckFail(data)) return null; // Validation
		
	}
	
	return data.TestFrequencyCGM * data.SensorCGMUsageWeeks * 7 + data.TestFrequencySMBG * (52 - data.SensorCGMUsageWeeks) * 7;
}

export function CostPerTest(data=[]) {
	
	if (data.length === 0) {
		
		data = {
			'TestFrequencySMBG': GetFieldValue('TestFrequencySMBG'),
			'DeviceCostSMBG': GetFieldValue('DeviceCostSMBG'), // Page 1
			'DeviceUsageSMBG': GetFieldValue('DeviceUsageSMBG'), // Page 1
			'CostTestStripSMBG': GetFieldValue('CostTestStripSMBG'),
			'CostLancingDevice': GetFieldValue('CostLancingDevice'), // Page 1
			'LancetUsageSMBG': GetFieldValue('LancetUsageSMBG'), // Page 1
			'LancetCostSMBG': GetFieldValue('LancetCostSMBG'),
			'LancetDurationSMBG': GetFieldValue('LancetDurationSMBG'),
			'TestsPA_SMBG': TestsPA_SMBG(),
		};
		
		if (numCheckFail(data)) return null; // Validation
		
	}
	
	if ( data.TestFrequencySMBG === 0 && typeof data.TestFrequencySMBG === 'number' ) {
		return 0;
	} else {
		return data.DeviceCostSMBG / (data.DeviceUsageSMBG * data.TestsPA_SMBG) +
			data.CostTestStripSMBG + data.CostLancingDevice / (data.LancetUsageSMBG * data.TestsPA_SMBG) + data.LancetCostSMBG / data.LancetDurationSMBG;
	}
}

export function CostPerDaySMBG(data=[]) {
	
	if (data.length === 0) {
		
		data = {
			'TestFrequencySMBG': GetFieldValue('TestFrequencySMBG'),
			'CostPerTest': CostPerTest(),
		};
		
		if (numCheckFail(data)) return null; // Validation
		
	}
	
	return data.CostPerTest * data.TestFrequencySMBG;
	
}

export function CostPerDayCGM(data=[]) {
	
	if (data.length === 0) {
		
		data = {
			'TestFrequencyCGM': GetFieldValue('TestFrequencyCGM'),
			'CostPerTest': CostPerTest(),
		};
		
		if (numCheckFail(data)) return null; // Validation
		
	}
	
	return data.CostPerTest * data.TestFrequencyCGM;
	
}


// Page 4

function CalculationCycle() {
	
	let  CalculationCycle = GetFieldValue('CalculationCycle');
	
	if ( isEmpty(CalculationCycle) ) CalculationCycle = 1;
	
	return CalculationCycle;
	
}

function TotalPatients() {
	
	let  TotalPatients = GetFieldValue('TotalPatients');
	
	if ( isEmpty(TotalPatients) ) TotalPatients = 1;
	
	return TotalPatients;
	
}

function PresentValueDiscount() {
	
	let  PresentValueDiscount = GetFieldValue('PresentValueDiscount');
	
	if ( isEmpty(PresentValueDiscount) ) PresentValueDiscount = 0;
	
	return PresentValueDiscount / 100;
	
}

function CalcPresentValue(value) {
	
	let data = {
		'CalculationCycle': CalculationCycle(),
		'PresentValueDiscount': PresentValueDiscount(),
	};
	
	let newvalue = 0;
	
	if ( data.PresentValueDiscount > 0) {
		
		let c = Math.floor(data.CalculationCycle);
		
		for (let i = 0; i < c; i++) {
			newvalue += value / Math.pow(1 + data.PresentValueDiscount, i );
		}
		
	} else {
		newvalue = value * data.CalculationCycle;
	}
	
	return newvalue;
}

function TotalOtherCostsSMBG() {
	
	const data = {
		//'CalculationCycle': CalculationCycle(),
		'TotalPatients': TotalPatients(),
		'OtherCostsSMBG': GetFieldValue('OtherCostsSMBG'),
	};
	
	if ( isEmpty(data.OtherCostsSMBG) ) data.OtherCostsSMBG = 0;
	
	//return data.OtherCostsSMBG * data.TotalPatients * data.CalculationCycle;
	return CalcPresentValue(data.OtherCostsSMBG * data.TotalPatients);
	
}

function TotalOtherCostsCGM() {
	
	const data = {
		//'CalculationCycle': CalculationCycle(),
		'TotalPatients': TotalPatients(),
		'OtherCostsCGM': GetFieldValue('OtherCostsCGM'),
	};
	
	if ( isEmpty(data.OtherCostsCGM) ) data.OtherCostsCGM = 0;
	
	//return data.OtherCostsCGM * data.TotalPatients * data.CalculationCycle;
	return CalcPresentValue(data.OtherCostsCGM * data.TotalPatients );
	
}


export function TotalUpfrontCostSMBG(data=[]) {
	
	if (data.length === 0) {
		
		data = {
			//'CalculationCycle': CalculationCycle(),
			'TotalPatients': TotalPatients(),
			'DeviceUsageSMBG': GetFieldValue('DeviceUsageSMBG'), // Screen 1
			'DeviceCostSMBG': GetFieldValue('DeviceCostSMBG'), // Screen 1
		};
		
	}
	
	//return TotalUpfrontCostSMBG * data.CalculationCycle * data.TotalPatients; // Old formual
	const TotalUpfrontCostSMBG = math.ceil(1 / data.DeviceUsageSMBG) * data.DeviceCostSMBG; // Original excel formula
	
	//const TotalUpfrontCostSMBG = math.ceil(data.CalculationCycle / data.DeviceUsageSMBG) * data.DeviceCostSMBG; // Updated formula
	return TotalUpfrontCostSMBG * data.TotalPatients;
	
}

export function TotalUpfrontCostCGM(data=[]) {
	
	if (data.length === 0) {
		
		data = {
			//'CalculationCycle': CalculationCycle(),
			'TotalPatients': TotalPatients(),
			'DeviceUsageCGM': GetFieldValue('DeviceUsageCGM'), // Screen 1
			'DeviceCostCGM': GetFieldValue('DeviceCostCGM'), // Screen 1
			
			//'ReceiverCostCGM': GetFieldValue('ReceiverCostCGM'), // Screen 1
			//'ReceiverUsageCGM': GetFieldValue('ReceiverUsageCGM'), // Screen 1
			
		};
	
	}
	
	//return TotalUpfrontCostCGM * data.CalculationCycle * data.TotalPatients; // Old formula
	const TotalUpfrontCostCGM = math.ceil(1 / data.DeviceUsageCGM) * data.DeviceCostCGM; // Original excel formula
	
	//const TotalReceiverCostCGM = math.ceil(1 / data.ReceiverUsageCGM) * data.ReceiverCostCGM;
	
	//const TotalUpfrontCostCGM = math.ceil(data.CalculationCycle / data.DeviceUsageCGM) * data.DeviceCostCGM; // Updated formula
	//return (TotalUpfrontCostCGM + TotalReceiverCostCGM) * data.TotalPatients;
	return TotalUpfrontCostCGM * data.TotalPatients;
	
}

export function TotalSensorCostSMBG() {
	return 0;
}

export function TotalSensorCostCGM(data=[]) {
	
	if (data.length === 0) {
		
		data = {
			//'CalculationCycle': CalculationCycle(),
			'TotalPatients': TotalPatients(),
			'SensorsCGMNoStandard': SensorsCGMNoStandard(),
			'SensorsCGMWasted': SensorsCGMWasted(),
			
			'SensorCGMCost': GetFieldValue('SensorCGMCost'), // Screen 2
			'SensorCGMUsageWeeks': GetFieldValue('SensorCGMUsageWeeks'), // Screen 2
			
			'TransmitterCostCGM': GetFieldValue('TransmitterCostCGM'), // Screen 2
			'TransmitterUsageCGM': GetFieldValue('TransmitterUsageCGM'), // Screen 2
			
		};
		
	}
	
	const SensorCost = ((data.SensorsCGMNoStandard + data.SensorsCGMWasted ) * data.SensorCGMCost);
	
	//const TransmitterCost = (data.SensorCGMUsageWeeks * 7) / data.TransmitterUsageCGM * data.TransmitterCostCGM; // Original formula for Max usage days.
	const TransmitterCost = data.TransmitterUsageCGM * data.TransmitterCostCGM; // New formula for transmitters used per year.
	
	//return (SensorCost + TransmitterCost) * data.CalculationCycle * data.TotalPatients;
	return CalcPresentValue( (SensorCost + TransmitterCost) * data.TotalPatients );
	
}

export function TotalTestCostSMBG(data=[]) {
	
	if (data.length === 0) {
		
		data = {
			//'CalculationCycle': CalculationCycle(),
			'TotalPatients': TotalPatients(),
			'CostPerTest': CostPerTest(),
			'TestsPA_SMBG': TestsPA_SMBG(),
		};
		
	}
	
	const TotalTestCostSMBG = data.CostPerTest * data.TestsPA_SMBG;
	
	//return TotalTestCostSMBG * data.CalculationCycle * data.TotalPatients;
	return CalcPresentValue( TotalTestCostSMBG * data.TotalPatients );
	
}

export function TotalTestCostCGM(data=[]) {
	
	if (data.length === 0) {
		
		data = {
			//'CalculationCycle': CalculationCycle(),
			'TotalPatients': TotalPatients(),
			'CostPerTest': CostPerTest(),
			'TestsPA_CGM': TestsPA_CGM(),
		};
	
	}
		
	const TotalTestCostCGM = data.CostPerTest * data.TestsPA_CGM;
	
	//return TotalTestCostCGM * data.CalculationCycle * data.TotalPatients;
	return CalcPresentValue( TotalTestCostCGM * data.TotalPatients );
	
}

export function TotalCostSMBG(data=[]) {
	
	if (data.length === 0) {
		
		data = {
			//'CalculationCycle': CalculationCycle(),
			'TotalUpfrontCostSMBG': TotalUpfrontCostSMBG(),
			'TotalSensorCostSMBG': TotalSensorCostSMBG(),
			'TotalTestCostSMBG': TotalTestCostSMBG(),
			'TotalOtherCostsSMBG': TotalOtherCostsSMBG(),
		};
		
		//data.TotalTestCostSMBG = data.TotalTestCostSMBG;
		//data.TotalUpfrontCostSMBG = data.TotalUpfrontCostSMBG;
	
	}
	
	return data.TotalUpfrontCostSMBG + data.TotalSensorCostSMBG + data.TotalTestCostSMBG + data.TotalOtherCostsSMBG;
	
}

export function TotalCostCGM(data=[]) {
	
	if (data.length === 0) {
		
		data = {
			'TotalUpfrontCostCGM': TotalUpfrontCostCGM(),
			'TotalSensorCostCGM': TotalSensorCostCGM(),
			'TotalTestCostCGM': TotalTestCostCGM(),
			'TotalOtherCostsCGM': TotalOtherCostsCGM(),
		};
		
	}
	
	return data.TotalUpfrontCostCGM + data.TotalSensorCostCGM + data.TotalTestCostCGM + data.TotalOtherCostsCGM;
	
}