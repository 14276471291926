import * as Yup from "yup";
import formModel from './formModel';

const { errorMessage } = formModel;

const {
	formField: {
		
		DeviceCostSMBG,
		DeviceUsageSMBG,
		DeviceCostCGM,
		DeviceUsageCGM,
		//ReceiverCostCGM,
		//ReceiverUsageCGM,
		CostLancingDevice,
		LancetUsageSMBG,
		
		SensorCGMUsageWeeks,
		SensorCGMUsage,
		SensorCGMCost,
		IncompleteUsageCGMPerc,
		TransmitterCostCGM,
		TransmitterUsageCGM,
		
		LancetCostSMBG,
		LancetDurationSMBG,
		CostTestStripSMBG,
		TestFrequencySMBG,
		TestFrequencyCGM,
		
		CalculationCycle,
		TotalPatients,
		PresentValueDiscount,
		OtherCostsSMBG,
		OtherCostsCGM,
		
	}
} = formModel;



/**
 * Set default error messages.
 */
Yup.setLocale({
	mixed: {
		required: `${errorMessage.requiredErrorMsg}`
	},
	number: {
		positive: `${errorMessage.positiveErrorMsg}`
	},
});


/**
 * Yup Validation
 * @link: https://github.com/jquense/yup#api
 *
 */
const validationSchema = [
	
	
	// Screen 1
	Yup.object().shape({
		
		[DeviceCostSMBG.name]: Yup.number()
			.typeError(`${errorMessage.numberErrorMsg}`)
			.min(0, `${errorMessage.positiveErrorMsg}`)
			//.positive()
			.required(),
			//.required(`${DeviceCostSMBG.requiredErrorMsg}`), // Example of field specific message.
		
		[DeviceUsageSMBG.name]: Yup.number()
			.typeError(`${errorMessage.numberErrorMsg}`)
			.min(1, `${errorMessage.onetotenErrorMsg}`)
			.max(10, `${errorMessage.onetotenErrorMsg}`)
			.required(),
		
		[DeviceCostCGM.name]: Yup.number()
			.typeError(`${errorMessage.numberErrorMsg}`)
			.min(0, `${errorMessage.positiveErrorMsg}`)
			//.positive()
			.required(),
		
		[DeviceUsageCGM.name]: Yup.number()
			.typeError(`${errorMessage.numberErrorMsg}`)
			.min(1, `${errorMessage.onetotenErrorMsg}`)
			.max(10, `${errorMessage.onetotenErrorMsg}`)
			.required(),
		/*
		[ReceiverCostCGM.name]: Yup.number()
			.typeError(`${errorMessage.numberErrorMsg}`)
			.min(0, `${errorMessage.positiveErrorMsg}`)
			//.positive()
			.required(),
		
		[ReceiverUsageCGM.name]: Yup.number()
			.typeError(`${errorMessage.numberErrorMsg}`)
			.min(1, `${errorMessage.onetotenErrorMsg}`)
			.max(10, `${errorMessage.onetotenErrorMsg}`)
			.required(),
		*/
		[CostLancingDevice.name]: Yup.number()
			.typeError(`${errorMessage.numberErrorMsg}`)
			.min(0, `${errorMessage.positiveErrorMsg}`)
			//.positive()
			.required(),
		
		[LancetUsageSMBG.name]: Yup.number()
			.typeError(`${errorMessage.numberErrorMsg}`)
			.min(1, `${errorMessage.onetotenErrorMsg}`)
			.max(10, `${errorMessage.onetotenErrorMsg}`)
			.required(),
		
	}),
	
	
	// Screen 2
	Yup.object().shape({
		
		[SensorCGMUsageWeeks.name]: Yup.number()
			.typeError(`${errorMessage.numberErrorMsg}`)
			.min(0, `${errorMessage.zerotofiftytwoErrorMsg}`)
			.max(52, `${errorMessage.zerotofiftytwoErrorMsg}`)
			.required(),
		
		[SensorCGMUsage.name]: Yup.number()
			.typeError(`${errorMessage.numberErrorMsg}`)
			.min(1, `${errorMessage.onetofiftyErrorMsg}`)
			.max(50, `${errorMessage.onetofiftyErrorMsg}`)
			.required(),
		
		[IncompleteUsageCGMPerc.name]: Yup.number()
			.typeError(`${errorMessage.numberErrorMsg}`)
			.min(0, `${errorMessage.zerotohundredErrorMsg}`)
			.max(100, `${errorMessage.zerotohundredErrorMsg}`)
			.required(),
		
		[SensorCGMCost.name]: Yup.number()
			.typeError(`${errorMessage.numberErrorMsg}`)
			.min(0, `${errorMessage.zerotohundredErrorMsg}`)
			.max(100, `${errorMessage.zerotohundredErrorMsg}`)
			//.positive()
			.required(),
		
		[TransmitterCostCGM.name]: Yup.number()
			.typeError(`${errorMessage.numberErrorMsg}`)
			//.positive()
			.min(0, `${errorMessage.positiveErrorMsg}`)
			//.max(100, `${errorMessage.zerotohundredErrorMsg}`)
			.required(),
		
		[TransmitterUsageCGM.name]: Yup.number()
			.typeError(`${errorMessage.numberErrorMsg}`)
			.min(0, `${errorMessage.positiveErrorMsg}`)
			//.max(100, `${errorMessage.onetohundredErrorMsg}`)
			//.positive()
			.required(),
	}),
	
	
	// Screen 3
	Yup.object().shape({
		
		[LancetCostSMBG.name]: Yup.number()
			.typeError(`${errorMessage.numberErrorMsg}`)
			.positive()
			.required(),
		
		[LancetDurationSMBG.name]: Yup.number()
			.typeError(`${errorMessage.numberErrorMsg}`)
			.min(1, `${errorMessage.onetotwentyErrorMsg}`)
			.max(20, `${errorMessage.onetotwentyErrorMsg}`)
			.required(),
		
		[CostTestStripSMBG.name]: Yup.number()
			.typeError(`${errorMessage.numberErrorMsg}`)
			.positive()
			.required(),
		
		[TestFrequencySMBG.name]: Yup.number()
			.typeError(`${errorMessage.numberErrorMsg}`)
			.min(0, `${errorMessage.zerotofiftyErrorMsg}`)
			.max(50, `${errorMessage.zerotofiftyErrorMsg}`)
			.required(),
		
		[TestFrequencyCGM.name]: Yup.number()
			.typeError(`${errorMessage.numberErrorMsg}`)
			.min(0, `${errorMessage.zerototwentyErrorMsg}`)
			.max(20, `${errorMessage.zerototwentyErrorMsg}`)
			.required(),
	
	}),
	
	
	// Screen 4
	Yup.object().shape({
		
		[CalculationCycle.name]: Yup.number()
			.typeError(`${errorMessage.numberErrorMsg}`)
			//.positive(),
			.min(1, `${errorMessage.onetofourErrorMsg}`)
			.max(4, `${errorMessage.onetofourErrorMsg}`)
			//.min(1, `${errorMessage.onetotenErrorMsg}`)
			//.max(10, `${errorMessage.onetotenErrorMsg}`),
			.required(),
		
		[TotalPatients.name]: Yup.number()
			.typeError(`${errorMessage.numberErrorMsg}`)
			.positive(),
			//.required(),
		
		[PresentValueDiscount.name]: Yup.number()
			.typeError(`${errorMessage.numberErrorMsg}`)
			.min(0, `${errorMessage.positiveErrorMsg}`),
			//.required(),
		
		[OtherCostsSMBG.name]: Yup.number()
			.typeError(`${errorMessage.numberErrorMsg}`)
			.min(0, `${errorMessage.positiveErrorMsg}`),
			//.required(),
		
		[OtherCostsCGM.name]: Yup.number()
			.typeError(`${errorMessage.numberErrorMsg}`)
			.min(0, `${errorMessage.positiveErrorMsg}`)
			//.required(),
		
	})
	
];

export default validationSchema;