import formModel from './formModel';

const {
  
  /**
   * Note: The letter number code represents Excel coordinates in the
   * AbbottLibreModel document DeviceCost tab.
   */
  formField: {
    
    // Screen 1
    DeviceCostSMBG, // E12 // $$
    DeviceUsageSMBG, // E13 // Year(s)
    DeviceCostCGM, // K12 // $$
    DeviceUsageCGM, // K13 // Year(s)
    //ReceiverCostCGM, // New field for v1.1
    //ReceiverUsageCGM, // New field for v1.1
    CostLancingDevice, // E15 // $$
    LancetUsageSMBG, // E16 // Year(s) // Originally not a named range
  
    // Screen 2
    SensorCGMUsageWeeks, // H18 // Usage - Weeks/year
    SensorCGMUsage, // H20 // Maximal usage - Days
    SensorCGMCost, // H19 // Cost per sensor - $$
    IncompleteUsageCGMPerc, // H22 // Incomplete usage
    TransmitterCostCGM, // New field for v1.1
    TransmitterUsageCGM, // New field for v1.1
    
    // Screen 3
    LancetCostSMBG, // E26 // $$
    LancetDurationSMBG, // E27 // tests / lancet
    CostTestStripSMBG, // E29 // $$ // Originally CostTestStrip
    TestFrequencySMBG, // E30 // tests / day
    TestFrequencyCGM, // H30 // tests / day // Originally TestFrequencyLibre
    
    // Screen 4
    CalculationCycle, // Not part of the Excel doc
    TotalPatients, // Not part of the Excel doc
    PresentValueDiscount, // Not part of the Excel doc
    OtherCostsSMBG, // Not part of the Excel doc
    OtherCostsCGM, // Not part of the Excel doc
    
  }
  
} = formModel;

const formInitialValues = {
  
  // Screen 1
  [DeviceCostSMBG.name]: 24, // 24
  [DeviceUsageSMBG.name]: 4, // 4
  [DeviceCostCGM.name]: 70, // 0
  [DeviceUsageCGM.name]: 3, // 3
  //[ReceiverCostCGM.name]: 65.34,
  //[ReceiverUsageCGM.name]: 1,
  [CostLancingDevice.name]: 0, // 0
  [LancetUsageSMBG.name]: 2, // 2
  
  // Screen 2
  [SensorCGMUsageWeeks.name]: 38, // 50
  [SensorCGMUsage.name]: 14, // 14
  [SensorCGMCost.name]: 35, // 35
  [IncompleteUsageCGMPerc.name]: 10, // 10%
  
  [TransmitterCostCGM.name]: 248.03,
  [TransmitterUsageCGM.name]: 4, // Was 90
  
  // Screen 3
  [LancetCostSMBG.name]: 0.12, // 0.12
  [LancetDurationSMBG.name]: 1, // 1.0
  [CostTestStripSMBG.name]: 0.4, // 0.40
  [TestFrequencySMBG.name]: 1.1, // 1.1
  [TestFrequencyCGM.name]: 0.8, // 0.82
  
  // Screen 4
  [CalculationCycle.name]: 1,
  [TotalPatients.name]: 100,
  [PresentValueDiscount.name]: 0,
  [OtherCostsSMBG.name]: 0,
  [OtherCostsCGM.name]: 0,
  
};

export default formInitialValues;