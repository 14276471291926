import InputField from "../InputField";
import Sticky from 'react-sticky-el';
import formModel from "../FormModel/formModel";
import Tooltip from "../Tooltip";
import DynamicCalculation from "../DynamicCalculation";


export default function SMBGCost(props) {
	
	const {
		formField: {
			LancetCostSMBG,
			LancetDurationSMBG,
			CostTestStripSMBG,
			TestFrequencySMBG,
			TestFrequencyCGM,
		}
	} = props;
	
	// const { currencySymbol } = formModel;
	
	
	return (
		
		<div className="calculator-container">
			
			
			{/* CALC HEADER */}
			<Sticky>
			<div className="row calc-header bg-gradiant text-white">
				<div className="col-12 col-lg-2 col-sm d-none d-lg-block">
				
				</div>
				<div className="col-12 col-sm d-none d-md-block">
				
				</div>
				<div className="col-6 col-md text-center">
					<span>SMBG</span>
				</div>
				<div className="col-6 col-md text-center">
					<span>CGM/FGM</span>
				</div>
			</div>{/* .row */}
			</Sticky>
			
			
			{/* CALC BODY - ROW 1 */}
			<div className="row calc-body">
				<div className="col-12 col-lg-2">
					<span className="row-title"> {/* Section Title */}
						<p className="input-row section-title pt-sm-0 pt-md-3 m-0">Lancets</p>
					</span>
				</div>
				<div className="col-12 col-md">
					<p className="input-row m-0">
						<span className="label">Cost</span>
						<Tooltip>
							What is the cost per&nbsp;lancet?
						</Tooltip>
					</p>
				</div>
				<div className="col">
					<div className="form-group form-floating">
						<InputField
							formik={props.state.formRef}
							name={LancetCostSMBG.name}
							label={LancetCostSMBG.label}
							prefix={props.state.currencySymbol + ' '}
							placeholder={props.state.currencySymbol}
						/>
					</div>
				</div>
				<div className="col">
					<p className="input-row text-center">
						<DynamicCalculation
							name="LancetCost"
							formik={props.state.formRef}
							type="currency"
							currencySymbol={props.state.currencySymbol}
						/>
					</p>
				</div>
			</div>{/* .row */}
			
			
			{/* CALC BODY - ROW 2 */}
			<div className="row calc-body">
				<div className="col-12 col-lg-2">
				
				</div>
				<div className="col-12 col-md">
					<p className="input-row m-0">
						<span className="label">Lifetime</span>
						<Tooltip>
							If patients tends to use the same lancet for more than one test, how many blood glucose tests is the lancet used for?
							<hr/>
							If the lancet is only used for one test, enter 1.
						</Tooltip>
					</p>
				</div>
				<div className="col">
					<div className="form-group form-floating">
						<InputField
							type="number"
							name={LancetDurationSMBG.name}
							label={LancetDurationSMBG.label}
						/>
					</div>
				</div>
				<div className="col">
					<p className="input-row text-center">
						<DynamicCalculation
							name="LancetDuration"
							formik={props.state.formRef}
						/>
					</p>
				</div>
			</div>{/* .row */}
			
			
			{/* CALC BODY - HR */}
			<div className="row calc-body">
				<div className="col py-2">
					<hr/>
				</div>
			</div>
			
			
			{/* CALC BODY - ROW 3 */}
			<div className="row calc-body">
				<div className="col-12 col-lg-2">
					<span className="row-title"> {/* Section Title */}
						<p className="input-row section-title pt-sm-0 pt-md-3 m-0">Tests</p>
					</span>
				</div>
				<div className="col-12 col-md">
					<p className="input-row m-0">
						<span className="label">Cost per strip</span>
						<Tooltip>
							What is the acquisition cost per strip?
						</Tooltip>
					</p>
				</div>
				<div className="col">
					<div className="form-group form-floating">
						<InputField
							formik={props.state.formRef}
							name={CostTestStripSMBG.name}
							label={CostTestStripSMBG.label}
							prefix={props.state.currencySymbol  + ' '}
							placeholder={props.state.currencySymbol }
							decimalscale={3}
						/>
					</div>
				</div>
				<div className="col">
					<p className="input-row text-center">
						<DynamicCalculation
							name="CostTestStrip"
							formik={props.state.formRef}
							type="currency"
							maximumFractionDigits={3}
							currencySymbol={props.state.currencySymbol}
						/>
					</p>
				</div>
			</div>{/* .row */}
			
			
			{/* CALC BODY - ROW 4 */}
			<div className="row calc-body">
				<div className="col-12 col-lg-2">
				
				</div>
				<div className="col-12 col-md">
					<p className="input-row mb-3">
						<span className="label">Frequency</span>
						<Tooltip>
							On average, how many times per day do patients test their blood glucose?
						</Tooltip>
					</p>
				</div>
				<div className="col">
					<div className="form-group form-floating">
						<InputField
							type="number"
							name={TestFrequencySMBG.name}
							label={TestFrequencySMBG.label}
						/>
					</div>
				</div>
				<div className="col">
					<div className="form-group form-floating">
						<InputField
							type="number"
							name={TestFrequencyCGM.name}
							label={TestFrequencyCGM.label}
						/>
					</div>
				</div>
			</div>{/* .row */}
			
			
			{/* CALC BODY - ROW 5 */}
			<div className="row calc-body">
				<div className="col-12 col-lg-2">
				
				</div>
				<div className="col-12 col-md">
					<p className="input-row m-0 fw-bold mb-3 label">Per patient</p>
				</div>
				<div className="col">
					<p className="input-row fw-bold text-center">
						<DynamicCalculation
							name="TestsPA_SMBG"
							formik={props.state.formRef}
							append=" tests / year"
							round="round"
						/>
					</p>
				</div>
				<div className="col">
					<p className="input-row fw-bold text-center">
						<DynamicCalculation
							name="TestsPA_CGM"
							formik={props.state.formRef}
							append=" tests / year"
							round="round"
						/>
					</p>
				</div>
			</div>{/* .row */}
			
			
			{/* CALC BODY - ROW 6 */}
			<div className="row calc-body">
				<div className="col-12 col-lg-2">
				
				</div>
				<div className="col-12 col-md">
					<p className="input-row m-0 fw-bold mb-3 label">Cost per test</p>
				</div>
				<div className="col">
					<p className="input-row fw-bold text-center">
						<DynamicCalculation
							name="CostPerTest"
							formik={props.state.formRef}
							type="currency"
							currencySymbol={props.state.currencySymbol}
						/>
					</p>
				</div>
				<div className="col">
					<p className="input-row fw-bold text-center">
						<DynamicCalculation
							name="CostPerTest"
							formik={props.state.formRef}
							type="currency"
							currencySymbol={props.state.currencySymbol}
						/>
					</p>
				</div>
			</div>{/* .row */}
			
			
			{/* CALC BODY - ROW 7 */}
			<div className="row calc-body">
				<div className="col-12 col-lg-2">
				
				</div>
				<div className="col-12 col-md">
					<p className="input-row m-0 fw-bold mb-3 label">Cost per day</p>
				</div>
				<div className="col">
					<p className="input-row fw-bold text-center">
						<DynamicCalculation
							name="CostPerDaySMBG"
							formik={props.state.formRef}
							type="currency"
							currencySymbol={props.state.currencySymbol}
						/>
					</p>
				</div>
				<div className="col">
					<p className="input-row fw-bold text-center">
						<DynamicCalculation
							name="CostPerDayCGM"
							formik={props.state.formRef}
							type="currency"
							currencySymbol={props.state.currencySymbol}
						/>
					</p>
				</div>
			</div>{/* .row */}
			
		</div>
		
	) // return()
	
} // SMBGCost()