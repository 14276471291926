import React from "react";
import {Field, useField} from "formik";
import NumberFormat from 'react-number-format';
import {isEmpty} from "../Helpers";
//import {at} from "lodash";


export default function InputField(props) {
	
	const { errorText, ...rest } = props;
	const [field, meta] = useField(props);
	
	var inputField, hiddenField;
	
	
	if ( props.formik !== undefined ) {
		
		inputField = <NumberFormat
			//id={props.name}
			className={((meta.touched && meta.error) ? 'form-control is-invalid' : 'form-control') + (props.appendClass ? " " + props.appendClass : '')}
			thousandSeparator={( props.thousandseparator === 1 ? ',' : props.thousandseparator )}
			decimalSeparator={( props.decimalseparator === 1 ? '.' : props.decimalseparator )}
			decimalScale={( props.decimalscale ? props.decimalscale : 2 )}
			//prefix={props.prefix}
			placeholder=" "
			onValueChange={(values) => {
				
				//let { formattedValue, value } = values;
				let { value } = values;
				
				if ( ! isEmpty(value) ) value = Number(value);
				
				if ( props.formik.current !== null ) {
					props.formik.current.setFieldValue(props.name, value);
				}
				
			}}
			onBlur={(values) => {
				props.formik.current.setFieldTouched( props.name, true );
			}}
			defaultValue={meta.value}
			{...rest}
		/>;
		
		hiddenField = <Field
			type="hidden"
			className={((meta.touched && meta.error) ? 'form-control is-invalid' : 'form-control') + (props.appendClass ? " " + props.appendClass : '')}
			{...field}
			{...rest}
		/>;
		
	} else {
		
		inputField = <Field
			type={props.type}
			//id={props.name}
			className={((meta.touched && meta.error) ? 'form-control is-invalid' : 'form-control') + (props.appendClass ? " " + props.appendClass : '')}
			placeholder=" "
			{...field}
			{...rest}
		/>;
		
		hiddenField = '';
		
	}
	
	
	return (
		<React.Fragment>
			
			{hiddenField}
			
			{inputField}
			
			<label htmlFor={props.name} className="">{props.label}</label>
			
			{meta.touched && meta.error ? (
				<div className="invalid-feedback">{meta.error}</div>
			) : null}
			
		</React.Fragment>
	);
		

} // InputField()