import * as Calc from "Helpers";
import {isEmpty, processRounding } from "Helpers";
import formModel from "./FormModel/formModel";


export default function DynamicCalculation(props) {
	
	//const locale = navigator.language; // Browser Detection
	const locale = 'en-US';
	const currency = 'USD';
	//const { currencySymbol } = formModel;
	
	
	let prepend = props.prepend;
	let append = props.append;
	let type = ( props.type ? props.type : 'decimal' );
	let currencySymbol = ( props.currencySymbol ? props.currencySymbol : '' );
	let value;
	
	// Get calculated value from Helpers.js
	if ( typeof Calc[props.name] === 'function' ) {
		value = Calc[props.name]();
	} else {
		throw new Error( props.name + ' was not found in Helpers.js');
	}
	
	value = processRounding(value, props.round);
	
	if ( isNaN(value) || isEmpty(value) ) { // Empty Value
		
		prepend = null;
		append = null;
		value = (props.empty ? props.empty : " ");
		
	} else if (props.type === 'currency' ) { // Currency
		
		/*
		value = Number(value).toLocaleString(locale, {
			style: type,
			currency: currency,
			currencyDisplay: "symbol",
			minimumFractionDigits: props.minimumFractionDigits,
			maximumFractionDigits: props.maximumFractionDigits,
		});
		
		
		let currencySymbol = localeCurrencySymbol(locale, currency);
		value = value.replace(currencySymbol, " " + currencySymbol + " ");
		*/
		
		
		value = Number(value).toLocaleString(locale, {
			minimumFractionDigits: props.minimumFractionDigits,
			maximumFractionDigits: props.maximumFractionDigits,
		});
		
		value = currencySymbol + ' ' + value;
		
	} else { // Default Number
		
		value = Number(value).toLocaleString(locale, {
			minimumFractionDigits: props.minimumFractionDigits,
			maximumFractionDigits: props.maximumFractionDigits,
		});
		
	}
	
	
	return (
		<span>
			{prepend}{value}{append}
		</span>
	);
	
} // DynamicCalculation()
