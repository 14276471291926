import React from "react"
import Calculator from 'components/Calculator';

class App extends React.Component {
    
    
    constructor(props) {
        
        super(props);
        this.handleUnload = this.handleUnload.bind(this);
        this.state = {
            activeStep: 0,
            isLastStep: false,
        }
        
    } // constructor()
    
    
    componentDidMount() {
        
        // Listen for unload event
        window.addEventListener('beforeunload', this.handleUnload);
        
        // Set the tab
        //this.setState({ tabCount: document.querySelectorAll(".tab-panel").length });
        
    } // componentDidMount()
    
    
    componentWillUnmount() {
        
        // Clean up listener event
        window.removeEventListener('beforeunload', this.handleUnload);
        
    } // componentWillUnmount()
    
    
    // Ask user if they want to leave the page.
    handleUnload(e) {
        
        return true; // Temp - Check form first.
        //var message = "Are you sure you want to leave?";
        //(e || window.event).returnValue = message; //Gecko + IE
        //return message; //Gecko + Webkit, Safari, Chrome etc.
        
    } // handleUnload()
    
    
    processForm = (data) => {
        //console.log(data);
    }
    
    
    render() {
        return (
            <div className="container">
                
                <div className="screen-rotate-warning no-print">
                    Please rotate your screen to horizontal mode.
                </div>
                
                <div className="App">
                    
                    
                    <div className="topnav">
                            <nav className="navbar navbar-expand-lg navbar-light">
                                <div className="nav-brand roche-brand">
                                    <div className="logo-image-combo"></div>
                                    <div className="text-logo">
                                        <a href="https://www.rochediabetes.com/" target="_blank" rel="noopener noreferrer">Roche Diabetes website</a>
                                    </div>
                                    <div className="img-logo hide576">
                                        <a href="https://www.roche.com/" target="_blank" rel="noopener noreferrer">Roche website</a>
                                    </div>
                                </div>
                            </nav>
                    </div>
                    
                    
                    <Calculator/>
                    
                    
                </div> {/* .App */}
                
                
            </div>
        );
    } // render()
    
} // App

export default App;