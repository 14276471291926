import InputField from "../InputField";
import Sticky from 'react-sticky-el';
import formModel from "../FormModel/formModel";
import Tooltip from "../Tooltip";
import DynamicCalculation from "../DynamicCalculation";


export default function SensorCost(props) {
	
	const {
		formField: {
			SensorCGMUsageWeeks,
			SensorCGMUsage,
			SensorCGMCost,
			IncompleteUsageCGMPerc,
			TransmitterCostCGM,
			TransmitterUsageCGM,
		}
	} = props;
	
	//const { props.state.currencySymbol } = formModel; // TODO, rework with localeprops.state.currencySymbol()
	
	
	return (
		
		<div className="calculator-container">
			
			
			{/* CALC HEADER */}
			<Sticky>
			<div className="row calc-header bg-gradiant text-white">
				<div className="col-12 col-lg-2 col-sm d-none d-lg-block">
				
				</div>
				<div className="col-12 col-sm d-none d-md-block">
				
				</div>
				<div className="col-6 col-md text-center">
					<span>SMBG</span>
				</div>
				<div className="col-6 col-md text-center">
					<span>CGM/FGM</span>
				</div>
			</div>{/* .row */}
			</Sticky>
			
			
			{/* CALC BODY - ROW 1 */}
			<div className="row calc-body">
				<div className="col-12 col-lg-2">
					<span className="row-title"> {/* Section Title */}
						<p className="input-row section-title pt-sm-0 pt-md-3 m-0">Sensor</p>
					</span>
				</div>
				<div className="col-12 col-md">
					<p className="input-row m-0">
						<span className="label">Sensor Usage</span>
						<Tooltip>
							How many weeks per year do patients use sensors on average?
						</Tooltip>
					</p>
				</div>
				<div className="col">
					
				</div>
				<div className="col">
					<div className="form-group form-floating  mb-3">
						<InputField
							type="number"
							name={SensorCGMUsageWeeks.name}
							label={SensorCGMUsageWeeks.label}
							placeholder={ SensorCGMUsageWeeks.placeholder ? SensorCGMUsageWeeks.placeholder : ' ' }
						/>
					</div>
				</div>
			</div>{/* .row */}
			
			
			{/* CALC BODY - ROW 2 */}
			<div className="row calc-body">
				<div className="col-12 col-lg-2">
				
				</div>
				<div className="col-12 col-md">
					<p className="input-row m-0">
						<span className="label">Maximum usage per sensor</span>
						<Tooltip>
							How many days does a sensor last?
							<hr/>
							For population costs use an average number of&nbsp;days?
						</Tooltip>
					</p>
				</div>
				<div className="col">
				
				</div>
				<div className="col">
					<div className="form-group form-floating  mb-3">
						<InputField
							type="number"
							name={SensorCGMUsage.name}
							label={SensorCGMUsage.label}
							placeholder={ SensorCGMUsage.placeholder ? SensorCGMUsage.placeholder : ' ' }
						/>
					</div>
				</div>
			</div>{/* .row */}
			
			
			{/* CALC BODY - ROW 3 */}
			<div className="row calc-body">
				<div className="col-12 col-lg-2">
					<span className="row-title">
					
					</span>
				</div>
				<div className="col-12 col-md">
					<p className="input-row m-0">
						<span className="label">Total sensors per year</span>
					</p>
				</div>
				<div className="col">
				
				</div>
				<div className="col">
					<p className="input-row mb-3">
						<DynamicCalculation
							name="SensorsCGMNoStandard"
							formik={props.state.formRef}
							append=" sensors / year"
							round="ceil"
						/>
					</p>
				</div>
			</div>{/* .row */}
			
			
			{/* CALC BODY - ROW 4 */}
			<div className="row calc-body">
				<div className="col-12 col-lg-2">
				
				</div>
				<div className="col-12 col-md">
					<p className="input-row m-0">
						<span className="label">Incomplete Usage</span>
						<Tooltip>
							Based on the Maximum usage per sensor, what percentage of the days are lost due to incomplete usage?
							<hr/>
							If a sensor that can last up to 14 days is on average replaced every 7 days, enter 50%.
						</Tooltip>
					</p>
				</div>
				<div className="col">
				
				</div>
				<div className="col">
					<div className="form-group form-floating">
						<InputField
							formik={props.state.formRef}
							name={IncompleteUsageCGMPerc.name}
							label={IncompleteUsageCGMPerc.label}
							suffix={' %'}
							placeholder={' %'}
						/>
					</div>
				</div>
			</div>{/* .row */}
			
			
			{/* CALC BODY - ROW 5 */}
			<div className="row calc-body">
				<div className="col-12 col-lg-2">
				
				</div>
				<div className="col-12 col-md">
					<p className="input-row m-0">
						<span className="label">Wasted Sensors</span>
					</p>
				</div>
				<div className="col">
				
				</div>
				<div className="col">
					<p className="input-row mb-3">
						<DynamicCalculation
							name="SensorsCGMWasted"
							formik={props.state.formRef}
							append=" sensors / year"
							round={1}
						/>
					</p>
				</div>
			</div>{/* .row */}
			
			
			{/* CALC BODY - ROW 6 */}
			<div className="row calc-body">
				<div className="col-12 col-lg-2">
				
				</div>
				<div className="col-12 col-md">
					<p className="input-row m-0">
						<span className="label">Total Sensors</span>
					</p>
				</div>
				<div className="col">
				
				</div>
				<div className="col">
					<p className="input-row mb-3">
						<DynamicCalculation
							name="SensorsCGMTotalSensors"
							formik={props.state.formRef}
							append=" sensors / year"
							round={1}
						/>
					</p>
				</div>
			</div>{/* .row */}
			
			
			{/* CALC BODY - ROW 7 */}
			<div className="row calc-body">
				<div className="col-12 col-lg-2">
				
				</div>
				<div className="col-12 col-md ">
					<p className="input-row m-0">
						<span className="label">Cost per sensor</span>
						<Tooltip>
							What is the cost of acquiring 1 sensor?
						</Tooltip>
					</p>
				</div>
				<div className="col text-end">
					<p className="input-row mb-3 "> </p>
				</div>
				<div className="col ">
					<div className="form-group form-floating">
						<InputField
							formik={props.state.formRef}
							name={SensorCGMCost.name}
							label={SensorCGMCost.label}
							prefix={props.state.currencySymbol + ' '}
							placeholder={props.state.currencySymbol}
						/>
					</div>
				</div>
			</div>{/* .row */}
			
			{/* CALC BODY - HR */}
			<div className="row calc-body">
				<div className="col py-2">
					<hr/>
				</div>
			</div>
			
			{/* CALC BODY - ROW 8 */}
			<div className="row calc-body">
				<div className="col-12 col-lg-2">
					<span className="row-title"> {/* Section Title */}
						<p className="input-row section-title pt-sm-0 pt-md-3 m-0">Transmitter</p>
					</span>
				</div>
				<div className="col-12 col-md">
					<p className="input-row m-0">
						<span className="label">Transmitter cost</span>
						<Tooltip>
							What is the cost of each transmitter? If a transmitter is not required for the CGM system, please enter 0.
						</Tooltip>
					</p>
				</div>
				<div className="col">
				
				</div>
				<div className="col">
					<div className="form-group form-floating  mb-3">
						<InputField
							formik={props.state.formRef}
							name={TransmitterCostCGM.name}
							label={TransmitterCostCGM.label}
							prefix={props.state.currencySymbol + ' '}
							placeholder={props.state.currencySymbol}
						/>
					</div>
				</div>
			</div>{/* .row */}
			
			{/* CALC BODY - ROW 9 */}
			<div className="row calc-body">
				<div className="col-12 col-lg-2">
					<span className="row-title"> {/* Section Title */}
						<p className="input-row section-title pt-sm-0 pt-md-3 m-0"></p>
					</span>
				</div>
				<div className="col-12 col-md">
					<p className="input-row">
						<span className="label">Transmitters used per year</span>
					</p>
				</div>
				<div className="col">
				
				</div>
				<div className="col">
					<div className="form-group form-floating  mb-3">
						<InputField
							type="number"
							name={TransmitterUsageCGM.name}
							label={TransmitterUsageCGM.label}
							placeholder={ TransmitterUsageCGM.placeholder ? TransmitterUsageCGM.placeholder : ' ' }
						/>
					</div>
				</div>
			</div>{/* .row */}
			
			
			{/* CALC BODY - ROW 8 */}
			<div className="row calc-body">
				<div className="col-12 col-lg-2">
				
				</div>
				<div className="col-12 col-md bg-secondary rounded-bottom-left rounded-top-left">
					<p className="input-row m-0 fw-bold text-uppercase label">Total</p>
				</div>
				<div className="col bg-secondary">
				
				</div>
				<div className="col bg-secondary rounded-bottom-right rounded-top-right">
					<p className="input-row mb-0">
						<DynamicCalculation
							name="SensorsCGMTotalCost"
							formik={props.state.formRef}
							type="currency"
							currencySymbol={props.state.currencySymbol}
							//round="ceil"
							//minimumFractionDigits={2}
						/>
					</p>
				</div>
			</div>{/* .row */}
			
			
		</div>
		
	) // return()
	
} // SensorCost()