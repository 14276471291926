import React from "react";
import InputField from "../InputField";
import Sticky from 'react-sticky-el';
import formModel from "../FormModel/formModel";
import Tooltip from "../Tooltip";
import DynamicCalculation from "../DynamicCalculation";



export default function TotalCost(props) {
	
	const {
		formField: {
			CalculationCycle,
			TotalPatients,
			PresentValueDiscount,
			OtherCostsSMBG,
			OtherCostsCGM,
		}
	} = props;
	
	//const { currencySymbol } = formModel;
	
	return (
		<React.Fragment>
		
		<div className="calculator-container pb-0 mb-5">
			<div className="row calc-header bg-gradiant mb-0 pb-3">
				
				<div className="col-sm-12 col-md-4 text-center">
					<div className="text-white">
						<span className="input-label">Calculation Cycle</span>
						<Tooltip>
							Please enter the number of years that you would like to run the calculation.
						</Tooltip>
					</div>
					<div className="form-group form-floating" style={{'display':'inline-block', 'maxWidth': '250px'}}>
						<InputField
							type="number"
							name={CalculationCycle.name}
							label={CalculationCycle.label}
						/>
					</div>
				</div>
				
				<div className="col-sm-12 col-md-4 text-center">
					<div className="text-white">
						<span className="input-label">Total Patients</span>
					</div>
					<div className="form-group form-floating" style={{'display':'inline-block', 'maxWidth': '250px'}}>
						<InputField
							formik={props.state.formRef}
							name={TotalPatients.name}
							label={TotalPatients.label}
							thousandseparator={+true}
							decimalscale={0}
						/>
					</div>
				</div>
				
				<div className="col-sm-12 col-md-4 text-center">
					<div className="text-white">
						<span className="input-label">Present Value Discount</span>
						<Tooltip>
							Please enter the discount percentage you would like to apply for the Present Value calculation (eg 3.0 for 3%).  If you do not wish to utilize the Present Value functionality, please enter 0 and the tool will calculate costs without a discount.
						</Tooltip>
					</div>
					<div className="form-group form-floating" style={{'display':'inline-block', 'maxWidth': '250px'}}>
						<InputField
							formik={props.state.formRef}
							name={PresentValueDiscount.name}
							label={PresentValueDiscount.label}
							suffix={' %'}
							placeholder={' %'}
						/>
					</div>
				</div>
				
			</div>{/* .row */}
		</div>
		
		<div className="calculator-container">
			
			
			{/* CALC HEADER */}
			<Sticky>
			<div className="row calc-header bg-gradiant text-white">
				<div className="col-12 col-lg-2 col-sm d-none d-lg-block">
				
				</div>
				<div className="col-12 col-sm d-none d-md-block">
				
				</div>
				<div className="col-6 col-md text-center">
					<span>SMBG</span>
				</div>
				<div className="col-6 col-md text-center">
					<span>CGM/FGM</span>
				</div>
			</div>{/* .row */}
			</Sticky>
			
			
			{/* CALC BODY - ROW */}
			<div className="row calc-body">
				<div className="col-12 col-lg-2">
					<span className="row-title"> {/* Section Title */}
						<p className="input-row section-title pt-sm-0 pt-md-3 m-0 text-nowrap">
							Other Costs
							<Tooltip>
								Please enter any other per patient annual costs that you'd like to include.  For example medical costs for each cohort.
							</Tooltip>
						</p>
					</span>
				</div>
				<div className="col-12 col-md ">
					<p className="input-row m-0 fw-bold mb-3"></p>
				</div>
				<div className="col">
					<div className="form-group form-floating">
						<InputField
							formik={props.state.formRef}
							name={OtherCostsSMBG.name}
							label={OtherCostsSMBG.label}
							prefix={props.state.currencySymbol  + ' '}
							placeholder={props.state.currencySymbol }
							decimalscale={3}
						/>
					</div>
				</div>
				<div className="col">
					<div className="form-group form-floating">
						<InputField
							formik={props.state.formRef}
							name={OtherCostsCGM.name}
							label={OtherCostsCGM.label}
							prefix={props.state.currencySymbol  + ' '}
							placeholder={props.state.currencySymbol }
							decimalscale={3}
						/>
					</div>
				</div>
			</div>{/* .row */}
			
			{/* CALC BODY - HR */}
			<div className="row calc-body">
				<div className="col py-2">
					<hr/>
				</div>
			</div>
			
			{/* CALC BODY - ROW 1 */}
			<div className="row calc-body">
				<div className="col-12 col-lg-2">
					<span className="row-title"> {/* Section Title */}
						<p className="input-row section-title pt-sm-0 pt-md-3 m-0">Totals</p>
					</span>
				</div>
				<div className="col-12 col-md">
					<p className="input-row m-0 fw-bold mb-3 label">Initial cost</p>
				</div>
				<div className="col">
					<p className="input-row fw-bold text-center">
						<DynamicCalculation
							name="TotalUpfrontCostSMBG"
							formik={props.state.formRef}
							type="currency"
							round="0"
							minimumFractionDigits={0}
							currencySymbol={props.state.currencySymbol}
						/>
					</p>
				</div>
				<div className="col">
					<p className="input-row fw-bold text-center">
						<DynamicCalculation
							name="TotalUpfrontCostCGM"
							formik={props.state.formRef}
							type="currency"
							round="0"
							minimumFractionDigits={0}
							currencySymbol={props.state.currencySymbol}
						/>
					</p>
				</div>
			</div>{/* .row */}
			
			
			{/* CALC BODY - ROW 2 */}
			<div className="row calc-body">
				<div className="col-12 col-lg-2">
				
				</div>
				<div className="col-12 col-md">
					<p className="input-row m-0 fw-bold mb-3 label">Sensor costs</p>
				</div>
				<div className="col">
					<p className="input-row fw-bold text-center">
						<DynamicCalculation
							name="TotalSensorCostSMBG"
							formik={props.state.formRef}
							type="currency"
							round="0"
							minimumFractionDigits={0}
							currencySymbol={props.state.currencySymbol}
						/>
					</p>
				</div>
				<div className="col">
					<p className="input-row fw-bold text-center">
						<DynamicCalculation
							name="TotalSensorCostCGM"
							formik={props.state.formRef}
							type="currency"
							round="0"
							minimumFractionDigits={0}
							currencySymbol={props.state.currencySymbol}
						/>
					</p>
				</div>
			</div>{/* .row */}
			
			
			{/* CALC BODY - ROW 3 */}
			<div className="row calc-body">
				<div className="col-12 col-lg-2">
				
				</div>
				<div className="col-12 col-md">
					<p className="input-row m-0 fw-bold mb-3 label">Lancets and strip costs</p>
				</div>
				<div className="col">
					<p className="input-row fw-bold text-center">
						<DynamicCalculation
							name="TotalTestCostSMBG"
							formik={props.state.formRef}
							type="currency"
							round="0"
							minimumFractionDigits={0}
							currencySymbol={props.state.currencySymbol}
						/>
					</p>
				</div>
				<div className="col">
					<p className="input-row fw-bold text-center">
						<DynamicCalculation
							name="TotalTestCostCGM"
							formik={props.state.formRef}
							type="currency"
							round="0"
							minimumFractionDigits={0}
							currencySymbol={props.state.currencySymbol}
						/>
					</p>
				</div>
			</div>{/* .row */}
			
			
			{/* CALC BODY - ROW 4 */}
			<div className="row calc-body">
				<div className="col-12 col-lg-2">
				
				</div>
				<div className="col-12 col-md bg-secondary rounded-top-left rounded-bottom-left">
					<p className="input-row m-0 fw-bold text-uppercase label">Total costs</p>
				</div>
				<div className="col bg-secondary text-center rounded-top-middle rounded-bottom-middle">
					<p className="input-row fw-bold mb-0">
						<DynamicCalculation
							name="TotalCostSMBG"
							formik={props.state.formRef}
							type="currency"
							round="0"
							minimumFractionDigits={0}
							currencySymbol={props.state.currencySymbol}
						/>
					</p>
				</div>
				<div className="col bg-secondary rounded-top-right rounded-bottom-right">
					<p className="input-row fw-bold mb-0 text-center">
						<DynamicCalculation
							name="TotalCostCGM"
							formik={props.state.formRef}
							type="currency"
							round="0"
							minimumFractionDigits={0}
							currencySymbol={props.state.currencySymbol}
						/>
					</p>
				</div>
			</div>{/* .row */}
			
		</div>
		
		</React.Fragment>
	) // return()
	
} // TotalCost()