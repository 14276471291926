import React from "react";
import Button from "react-bootstrap/Button";
import ProgressBar from "react-bootstrap/ProgressBar";
import Dropdown from "react-bootstrap/Dropdown";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import { Formik, Form } from "formik";


// Form Model
import formModel from './FormModel/formModel';
import formInitialValues from './FormModel/formInitialValues';
import validationSchema from './FormModel/validationSchema';


// Form Screens
import UpfrontCost from './Forms/UpfrontCost';
import SensorCost from './Forms/SensorCost';
import SMBGCost from './Forms/SMBGCost';
import TotalCost from './Forms/TotalCost';
import {isNumeric} from "mathjs";


// Global Varialbe
window.formRef = React.createRef(); // Formik Ref

// Constants
const { formId, formField } = formModel;

// Step Screens
const steps = ['Upfront Cost', 'Cost of Sensor', 'Cost of SMBG', 'Total Cost in Year(s)']; // TODO: Add Multilingual



// CalcUpfrontCost Component Class
class Calculator extends React.Component {
	
	
	constructor(props) {
		super(props);
		//this.formRef = formRef;
		
		let cs = JSON.parse(localStorage.getItem('currencySymbol')) || '$';
		
		if ( cs == 'CHF' ) {
			cs = '';
		}
		
		this.state = {
			formRef: window.formRef,
			nextStep: '',
			activeStep: 0,
			completedSteps: 0,
			steps: steps.length, // Total
			currencySymbol: cs,
		}
		//this.currentValidationSchema = validationSchema[this.state.activeStep];
		
		
	} // constructor()
	
	renderStepContent(step) {
		//console.log( 'step', this.state );
		switch (step) {
			case 0:
				return <UpfrontCost formField={formField} state={this.state} />;
			case 1:
				return <SensorCost formField={formField} state={this.state} />;
			case 2:
				return <SMBGCost formField={formField} state={this.state} />;
			case 3:
				return <TotalCost formField={formField} state={this.state} />;
			default:
				return <div>Not Found</div>;
		}
		
	} // renderStepContent()
	
	
	onKeyUpValue(event) {
		
		/**
		 * Detect keypress inside the form input fields.
		 * The Enter key will move to the next field, similar to how
		 * the Tab key works.
		 * @param event
		 */
		if (event.key === "Enter") {
			
			const form = event.target.form;
			//const inputs = form.querySelectorAll("input");
			const inputs = form.querySelectorAll("input:not([type=hidden])");
			const index = Array.prototype.indexOf.call(inputs, event.target);
			const next_el = inputs[index + 1];
			
			if (index === -1 ) return false; // Don't change Button behavior.
			
			if ( next_el !== undefined ) {
				next_el.focus();
			} else {
				window.formRef.current.submitForm(); // Submit Form
			}
			
			event.preventDefault();
			
		}
		
	} // onKeyUpValue()
	
	
	handleSubmit(values, actions) {
		
		//if (this.isLastStep()) {
		//  Submit form
		//}
			
		actions.setTouched({});
		actions.setSubmitting(false);
		
		this.progress_change(this.state.nextStep);
		
	} // handleSubmit()
	
	
	stepChange(action) {
		
		if ( action === 'next' ) {
			if ( ! this.isLastStep() ) {
				this.setState({nextStep: this.state.activeStep + 1}, () => {
					this.state.formRef.current.submitForm();
				});
			}
		} else if ( action === 'back') {
			if ( this.state.activeStep > 0 ) {
				this.setState({nextStep: this.state.activeStep - 1}, () => {
					this.state.formRef.current.submitForm();
				});
			}
		} else if ( isNumeric(action) ) {
			this.setState({nextStep: action}, () => {
				this.state.formRef.current.submitForm();
			});
		}
		
		// Move to top of the page
		window.scrollTo(0, 0);
		
		//this.tabChange('back', this.state.activeStep);
	}
	
	
	progress_change(i) {
		
		this.setState({activeStep: i});
		
		if ( i > this.state.completedSteps ) {
			this.setState({completedSteps: i});
		}
		
	} // progress_change()
	
	
	isLastStep() {
		return this.state.activeStep === steps.length - 1;
	}
	
	
	changeCurrencySymbol(cs) {
		if ( cs == 'CHF') {
			cs = '';
		}
		this.setState({
			currencySymbol: cs
		}, () => {
			if ( cs == '') {
				cs = 'CHF';
			}
			localStorage.setItem('currencySymbol', JSON.stringify(cs))
		});
	}
	
	
	render() {
		
		return (
			
			<Formik
				//innerRef={window.formRef}
				innerRef={this.state.formRef}
				initialValues={formInitialValues}
				onSubmit={(values, actions, ) => this.handleSubmit(values, actions)}
				validationSchema={validationSchema[this.state.activeStep]}
				enableReinitialize={true}
				validateOnBlur={true}
				validateOnChange={true}
				validateOnMount={false}
			>
				
				{(formik, isSubmitting, validateForm ) => (
					
					<Form
						id={formId}
						onSubmit={formik.handleSubmit}
						onKeyUp={this.onKeyUpValue.bind(this)}
						autoComplete="off"
					>
						
						<Tabs
							className="progress-tabs-container"
							selectedTabClassName="active"
							selectedTabPanelClassName="active"
							selectedIndex={this.state.activeStep}
							onSelect={ (index) => this.stepChange(index) }
						>
							<div className="container">
								
								
								{/* Progress Steps */}
								<div className="row no-print">
									{/*<div className="col d-none d-md-block">*/}
									<div className="col">
										<TabList className="tabs">
											{steps.map((label, i) => (
												<Tab key={i} disabled={( i > this.state.completedSteps ?? true)}>
													<span className="text-uppercase">{label}</span>
												</Tab>
											))}
										</TabList>
									</div>
								</div>
								
								
								{/* Main Panel */}
								<div className="row pt-5 pb-2">
									<div className="col">
										{steps.map((label, i) => (
											<TabPanel key={i} className="tab-panel">
												{this.renderStepContent(this.state.activeStep)}
											</TabPanel>
										))}
									</div>
								</div>
										
								
							</div>
							
							
							<div className="row calc-footer no-print">
								<div className="col text-start align-self-center">
									<div className="progress-bar-container">
										<ProgressBar now={ ((this.state.activeStep + 1) / steps.length) * 100 } />
									</div>
								</div>
								<div className="col text-center">
									<a className="help-link" href="help.html" target="_blank">Need help?</a>
								
									
									<Dropdown className="d-inline ms-4">
										<Dropdown.Toggle variant="outline-secondary" className="ps-3 pe-3">
											{ this.state.currencySymbol } &nbsp;
										</Dropdown.Toggle>
										
										<Dropdown.Menu>
											<Dropdown.Item
												{ ...(this.state.currencySymbol == '$' ? {active: 'true'} : '') }
												onClick={ () => this.changeCurrencySymbol('$') }
											>
												$
											</Dropdown.Item>
											<Dropdown.Item
												{ ...(this.state.currencySymbol == '£' ? {active: 'true'} : '') }
												onClick={() => this.changeCurrencySymbol('£')}
											>
												£
											</Dropdown.Item>
											<Dropdown.Item
												{ ...(this.state.currencySymbol == '€' ? {active: 'true'} : '') }
												onClick={() => this.changeCurrencySymbol('€')}
											>
												€
											</Dropdown.Item>
											<Dropdown.Item
												{ ...(this.state.currencySymbol == 'CHF' ? {active: 'true'} : '') }
												onClick={() => this.changeCurrencySymbol('CHF')}
											>
												CHF
											</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
									
								</div>
								<div className="col text-end">
									
									<Button
										id="back-button"
										disabled={ this.state.activeStep === 0 }
										className={ (this.state.activeStep === 0 ? 'hidden' : 'me-2') }
										onClick={ () => this.stepChange('back') }
									>
										Back
									</Button>
									
									<Button
										id="next-button"
										//disabled={isSubmitting}
										disabled={ this.state.activeStep === steps.length - 1 }
										//className={ (this.state.activeStep === steps.length - 1 ? 'hidden' : '') }
										onClick={ (e) => this.stepChange('next') }
									>
										{ (this.state.activeStep === steps.length - 1 ? 'Done' : 'Next') }
									</Button>
									
								</div>
							</div> {/* FOOTER END */}
							
						</Tabs>
						
					</Form>
					
				)}
				
			</Formik >
			
		); // return()
		
	} // render()
	
} // Calculator

export default Calculator;