import React, { useRef } from "react";
//import React, { useState, useRef } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";


export default function Custom_Tooltip(props) {
	
	//const [show, setShow] = useState(false);
	const target = useRef(null);
	const body = props.children;
	
	const renderTooltip = (props) => (
		<Tooltip className="button-tooltip" {...props}>
			{body}
		</Tooltip>
	);
	
	return (
		<React.Fragment>
			<OverlayTrigger
				trigger={["click", "focus"]}
				placement="right"
				delay={{ show: 250, hide: 250 }}
				overlay={renderTooltip}
			>
				<button type="button" className="tooltip-container" ref={target} >
					<i className="fas fa-info-circle"></i>
				</button>
			</OverlayTrigger>
		</React.Fragment>
	);

} // Custom_Tooltip()