import InputField from "../InputField";
import Sticky from 'react-sticky-el';
import formModel from "../FormModel/formModel";
import Tooltip from '../Tooltip';


export default function UpfrontCost(props) {

	const {
		formField: {
			DeviceCostSMBG,
			DeviceUsageSMBG,
			DeviceCostCGM,
			DeviceUsageCGM,
			//ReceiverCostCGM,
			//ReceiverUsageCGM,
			CostLancingDevice,
			LancetUsageSMBG,
		}
	} = props;
	
	//const { props.state.currencySymbol } = formModel;
	
	
	return (
		
		<div className="calculator-container">
			
			
			{/* CALC HEADER */}
			<Sticky>
			<div className="row calc-header bg-gradiant text-white">
				<div className="col-12 col-lg-2 col-sm d-none d-lg-block">
				
				</div>
				<div className="col-12 col-sm d-none d-md-block">
				
				</div>
				<div className="col-6 col-md text-center">
					<span>SMBG</span>
				</div>
				<div className="col-6 col-md text-center">
					<span>CGM/FGM</span>
				</div>
			</div>{/* .row */}
			</Sticky>
			
			
			{/* CALC BODY - ROW 1 */}
			<div className="row calc-body">
				<div className="col-12 col-lg-2">
					<span className="row-title"> {/* Section Title */}
						<p className="input-row section-title pt-sm-0 pt-md-3 m-0">Reader</p>
					</span>
				</div>
				<div className="col-12 col-md">
					<p className="input-row m-0">
						<span className="label">Device Cost</span>
						<Tooltip>
							What is the upfront cost of the reader?
							<hr/>
							If using a starter kit, enter 0 in the lancing device cost below.
						</Tooltip>
					</p>
				</div>
				<div className="col">
					<div className="form-group form-floating">
						<InputField
							formik={props.state.formRef}
							name={DeviceCostSMBG.name}
							label={DeviceCostSMBG.label}
							prefix={props.state.currencySymbol + ' '}
							placeholder={props.state.currencySymbol}
						/>
					</div>
				</div>
				<div className="col">
					<div className="form-group form-floating">
						<InputField
							formik={props.state.formRef}
							name={DeviceCostCGM.name}
							label={DeviceCostCGM.label}
							prefix={props.state.currencySymbol + ' '}
							placeholder={props.state.currencySymbol}
						/>
					</div>
				</div>
			</div>{/* .row */}
			
			
			{/* CALC BODY - ROW 2 */}
			<div className="row calc-body">
				<div className="col-12 col-lg-2">
				
				</div>
				<div className="col-12 col-md">
					<p className="input-row m-0">
						<span className="label">Lifetime</span>
						<Tooltip>
							How many years do you expect the device to last?
						</Tooltip>
					</p>
				</div>
				<div className="col">
					
					<div className="form-group form-floating">
						<InputField
							type="number"
							name={DeviceUsageSMBG.name}
							label={DeviceUsageSMBG.label}
						/>
					</div>
				</div>
				<div className="col">
					<div className="form-group form-floating">
						<InputField
							type="number"
							name={DeviceUsageCGM.name}
							label={DeviceUsageCGM.label}
						/>
					</div>
				</div>
			</div>{/* .row */}
			
			
			{/* CALC BODY - HR */}
			<div className="row calc-body">
				<div className="col py-2">
					<hr/>
				</div>
			</div>
			
			
			{/* CALC BODY - ROW 3 */}
			<div className="row calc-body">
				<div className="col-12 col-lg-2">
					<span className="row-title"> {/* Section Title */}
						<p className="input-row section-title pt-sm-0 pt-md-3 m-0">Lancing Device</p>
					</span>
				</div>
				<div className="col-12 col-md">
					<p className="input-row m-0">
						<span className="label">Device Cost</span>
						<Tooltip>
							What is the upfront cost of the lancing device.?
							<hr/>
							If using a starter kit in the Reader section, enter&nbsp;0.
						</Tooltip>
					</p>
				</div>
				<div className="col">
					<div className="form-group form-floating">
						<InputField
							formik={props.state.formRef}
							name={CostLancingDevice.name}
							label={CostLancingDevice.label}
							prefix={props.state.currencySymbol + ' '}
							placeholder={props.state.currencySymbol}
						/>
					</div>
				</div>
				<div className="col">
				
				</div>
			</div>{/* .row */}
			
			
			{/* CALC BODY - ROW 4 */}
			<div className="row calc-body">
				<div className="col-12 col-lg-2">
				
				</div>
				<div className="col-12 col-md">
					<p className="input-row m-0">
						<span className="label">Lifetime</span>
						<Tooltip>
							How many years do you expect the device to last?
						</Tooltip>
					</p>
				</div>
				<div className="col">
					<div className="form-group form-floating">
						<InputField
							type="number"
							name={LancetUsageSMBG.name}
							label={LancetUsageSMBG.label}
						/>
					</div>
				</div>
				<div className="col">
				
				</div>
			</div>{/* .row */}
			
			
		</div>
		
	) // return()
	
} // UpfrontCost()